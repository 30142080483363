<template>
  <v-main>
    <router-view></router-view>
  </v-main>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      id: this.$route.query.id,
    };
  },
};
</script>
